var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c_lp_text",class:_vm.textClasses},[(_vm.eyebrow)?_c('p',{staticClass:"c_lp_text__eyebrow respect-breaks",domProps:{"innerHTML":_vm._s(_vm.eyebrow)}}):_vm._e(),_vm._v(" "),(_vm.subheader)?_c('h2',{staticClass:"c_lp_text__subheader c_header--10 c_text--graphik_bold respect-breaks",attrs:{"id":_vm.slug(_vm.subheader)},domProps:{"innerHTML":_vm._s(_vm.subheader)}},[_vm._v("\n    "+_vm._s(_vm.subheader)+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.text)?_c('div',{staticClass:"c_lp_text__text c_text",class:_vm.columns != 1 ? ['c_lp_text__text--columns', 'c_lp_text__text--columns--' + _vm.columns, 'c_text--left'] : '',domProps:{"innerHTML":_vm._s(_vm.text)}}):_vm._e(),_vm._v(" "),(_vm.getHrefs(_vm.buttonLinkField))?_c('div',{staticClass:"c_lp_text__buttons c_buttons cta_container",class:[`c_buttons--${_vm.textAlignment}`]},[_c('a',{class:{
        'c_button primary-btn': _vm.primaryCtaBtnTheme === 'default' || _vm.primaryCtaBtnTheme === null,
        'c_button_2022 c_button_2022__primary': _vm.primaryCtaBtnTheme === 'default2023',
      },attrs:{"href":_vm.getHrefs(_vm.buttonLinkField),"aria-label":_vm.buttonLinkField.ariaLabel || null,"target":_vm.buttonLinkField.openInANewWindow ? '_blank' : '_self'}},[_vm._v("\n      "+_vm._s(_vm.buttonLinkField.buttonLabel)+"\n    ")])]):_vm._e(),_vm._v(" "),(_vm.getHrefs(_vm.buttonTwoLinkField))?_c('div',{staticClass:"c_lp_text__buttons c_buttons cta_container c_lp_text__buttons--mt",class:[`c_buttons--${_vm.textAlignment}`]},[_c('a',{class:{
        'c_button primary-btn': _vm.primaryCtaBtnTheme === 'default' || _vm.primaryCtaBtnTheme === null,
        'c_button_2022 c_button_2022__primary': _vm.primaryCtaBtnTheme === 'default2023',
      },attrs:{"href":_vm.getHrefs(_vm.buttonTwoLinkField),"aria-label":_vm.buttonTwoLinkField.ariaLabel || null,"target":_vm.buttonTwoLinkField.openInANewWindow ? '_blank' : '_self'}},[_vm._v("\n      "+_vm._s(_vm.buttonTwoLinkField.buttonLabel)+"\n    ")])]):_vm._e(),_vm._v(" "),(_vm.primaryCtaBtn.url)?_c('div',{staticClass:"c_lp_text__buttons c_buttons cta_container",class:[`c_buttons--${_vm.textAlignment}`]},[_c('a',{class:{
        'c_button primary-btn': _vm.primaryCtaBtnTheme === 'default' || _vm.primaryCtaBtnTheme === null,
        'c_button_2022 c_button_2022__primary': _vm.primaryCtaBtnTheme === 'default2023',
      },attrs:{"href":_vm.primaryCtaBtn.url,"aria-label":_vm.primaryCtaBtnAriaLabel,"target":_vm.primaryCtaBtnOpenInANewWindow ? '_blank' : '_self'}},[_vm._v("\n      "+_vm._s(_vm.primaryCtaBtnLabel)+"\n    ")])]):_vm._e(),_vm._v(" "),(_vm.btnUrl)?_c('div',{staticClass:"c_lp_text__buttons c_buttons cta_container",class:[`c_buttons--${_vm.textAlignment}`, {'c_lp_text__buttons--mt': _vm.getHrefs(_vm.buttonLinkField) || _vm.getHrefs(_vm.buttonTwoLinkField) || _vm.primaryCtaBtn.url } ]},[_c('a',{class:{
        'c_button primary-btn': _vm.primaryCtaBtnTheme === 'default' || _vm.primaryCtaBtnTheme === null,
        'c_button_2022 c_button_2022__primary': _vm.primaryCtaBtnTheme === 'default2023',
      },attrs:{"href":_vm.btnUrl,"aria-label":_vm.btnAriaLabel,"target":_vm.openInANewWindow ? '_blank' : '_self'}},[_vm._v("\n      "+_vm._s(_vm.btnLabel)+"\n    ")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }