<template>
  <div class="c_lp_text" :class="textClasses">
    <p class="c_lp_text__eyebrow respect-breaks" v-html="eyebrow" v-if="eyebrow"></p>
    <h2
      class="c_lp_text__subheader c_header--10 c_text--graphik_bold respect-breaks"
      v-html="subheader"
      v-if="subheader"
      :id="slug(subheader)"
    >
      {{ subheader }}
    </h2>
    <div
      class="c_lp_text__text c_text"
      :class="columns != 1 ? ['c_lp_text__text--columns', 'c_lp_text__text--columns--' + columns, 'c_text--left'] : ''"
      v-html="text"
      v-if="text"
    ></div>
    <div
      class="c_lp_text__buttons c_buttons cta_container"
      :class="[`c_buttons--${textAlignment}`]"
      v-if="getHrefs(buttonLinkField)"
    >
      <a
        :class="{
          'c_button primary-btn': primaryCtaBtnTheme === 'default' || primaryCtaBtnTheme === null,
          'c_button_2022 c_button_2022__primary': primaryCtaBtnTheme === 'default2023',
        }"
        :href="getHrefs(buttonLinkField)"
        :aria-label="buttonLinkField.ariaLabel || null"
        :target="buttonLinkField.openInANewWindow ? '_blank' : '_self'"
      >
        {{ buttonLinkField.buttonLabel }}
      </a>
    </div>
    <div
      class="c_lp_text__buttons c_buttons cta_container c_lp_text__buttons--mt"
      :class="[`c_buttons--${textAlignment}`]"
      v-if="getHrefs(buttonTwoLinkField)"
    >
      <a
        :class="{
          'c_button primary-btn': primaryCtaBtnTheme === 'default' || primaryCtaBtnTheme === null,
          'c_button_2022 c_button_2022__primary': primaryCtaBtnTheme === 'default2023',
        }"
        :href="getHrefs(buttonTwoLinkField)"
        :aria-label="buttonTwoLinkField.ariaLabel || null"
        :target="buttonTwoLinkField.openInANewWindow ? '_blank' : '_self'"
      >
        {{ buttonTwoLinkField.buttonLabel }}
      </a>
    </div>
    <div
      class="c_lp_text__buttons c_buttons cta_container"
      :class="[`c_buttons--${textAlignment}`]"
      v-if="primaryCtaBtn.url"
    >
      <a
        :class="{
          'c_button primary-btn': primaryCtaBtnTheme === 'default' || primaryCtaBtnTheme === null,
          'c_button_2022 c_button_2022__primary': primaryCtaBtnTheme === 'default2023',
        }"
        :href="primaryCtaBtn.url"
        :aria-label="primaryCtaBtnAriaLabel"
        :target="primaryCtaBtnOpenInANewWindow ? '_blank' : '_self'"
      >
        {{ primaryCtaBtnLabel }}
      </a>
    </div>
    <div class="c_lp_text__buttons c_buttons cta_container" :class="[`c_buttons--${textAlignment}`, {'c_lp_text__buttons--mt': getHrefs(buttonLinkField) || getHrefs(buttonTwoLinkField) || primaryCtaBtn.url } ]" v-if="btnUrl">
      <a
        :class="{
          'c_button primary-btn': primaryCtaBtnTheme === 'default' || primaryCtaBtnTheme === null,
          'c_button_2022 c_button_2022__primary': primaryCtaBtnTheme === 'default2023',
        }"
        :href="btnUrl"
        :aria-label="btnAriaLabel"
        :target="openInANewWindow ? '_blank' : '_self'"
      >
        {{ btnLabel }}
      </a>
    </div>
  </div>
</template>

<script>
import { slugify } from 'JS/global.js';
export default {
  props: {
    buttonLinkField: { type: Object, default: () => ({}) },
    buttonTwoLinkField: { type: Object, default: () => ({}) },
    eyebrow: String,
    subheader: String,
    text: String,
    columns: { type: Number, default: 1 },
    textAlignment: { type: String, default: 'left' },
    primaryCtaBtn: { type: Object, default: () => ({}) },
    primaryCtaBtnLabel: String,
    primaryCtaBtnAriaLabel: String,
    primaryCtaBtnTheme: { type: String, default: 'default' },
    primaryCtaBtnOpenInANewWindow: Boolean,
    btnCustomUrl: { type: String },
    btnLabel: { type: String },
    btnAriaLabel: { type: String },
    entry: { type: Array, default: () => [] },
    asset: { type: Array, default: () => [] },
    productCategory: { type: Array, default: () => [] },
    openInANewWindow: {type: Boolean, default: false}
  },
  computed: {
    textClasses() {
      const classes = [`c_text--${this.textAlignment}`, `c_lp_text--${this.textAlignment}`];
      return classes;
    },
    btnUrl() {
      // Check if the custom URL is set/exists, or check if there is an entry URL, if not, check if there is an asset URL, make sure it doesn't return an undefined string
      return (
        this.btnCustomUrl ||
        (this.entry && this.entry?.length > 0 ? this.entry?.[0]?.url : '') ||
        (this.asset && this.asset?.length > 0 ? this.asset?.[0]?.url : '') ||
        (this.productCategory && this.productCategory?.length > 0 ? this.productCategory?.[this.productCategory?.length - 1]?.url : '') ||
        ''
      );
    },
  },
  methods: {
    slug(subheader) {
      return slugify(subheader);
    },
    getHrefs(btn) {
      if (btn) {
        if (btn.entry?.length) {
          return this.localePath(`/${btn.entry?.[0].uri}`);
        }
        if (btn.productCategory?.length) {
          return this.localePath(`/${btn.productCategory[btn.productCategory.length - 1].uri}`);
        }
        if (btn.asset?.length && btn.asset?.[0]?.url) {
          return btn.asset?.[0]?.url;
        }
        if (btn.asset?.length && btn.asset?.[0]?.uri) {
          // URI is an alias for URL in this context.
          return btn.asset?.[0].uri;
        }
        if (btn.custom) {
          return btn.custom;
        }
      }
      return null;
    },
  },
  mounted() {
    // console.log('Asset URL:', this.asset.length > 0 ? this.asset[0].url : 'No asset URL');
    // console.log('primaryCtaBtn:', this.primaryCtaBtn);
    // console.log('primaryCtaBtnLabel:', this.primaryCtaBtnLabel);
    // console.log('primaryCtaBtnAriaLabel:', this.primaryCtaBtnAriaLabel);
    // console.log('primaryCtaBtnTheme:', this.primaryCtaBtnTheme);
    // console.log('primaryCtaBtnOpenInANewWindow:', this.primaryCtaBtnOpenInANewWindow);
    // console.log('btnCustomUrl:', this.btnCustomUrl);
    // console.log('btnLabel:', this.btnLabel);
    // console.log('btnAriaLabel:', this.btnAriaLabel);
    // console.log('asset:', this.asset);
    // console.log('btnUrl:', this.btnUrl);
    // console.log('productCategory:', this.productCategory);
  },
};
</script>

<style lang="scss">
.c_lp_text {
  max-width: var(--lp-text-max-width, 850px);
  display: flex;
  flex-direction: column;

  ul ul {
    list-style-type: disc;
    text-indent: -10px;
    padding-left: -20px;
  }

  ul ul li {
    position: relative;
    padding-left: 10px;
    margin-top: 10px;
  }

  ul li ul::before {
    list-style-type: none;
    content: '';
    position: absolute;
    left: -20px;
  }

  ul li ul li {
    list-style-type: none;
    content: '';
    position: relative;
    padding-left: 5px;

    &::before {
      content: '-';
      position: absolute;
      left: -10px;
      top: 0;
    }
  }

  &--left {
    margin-right: auto;
    align-items: flex-start;
  }
  &--center {
    margin-left: auto;
    margin-right: auto;
    align-items: center;
  }
  &--right {
    margin-left: auto;
    align-items: flex-end;
  }
  &__eyebrow {
    font-family: 'Graphik', sans-serif;
    font-size: 1.1rem;
    &:not(:last-child) {
      margin-bottom: var(--eyebrow-mb, 1.2rem);
    }
    @media (max-width: 768px) {
      font-family: 'Graphik', sans-serif;
      font-weight: 600;
      font-size: var(--eyebrow-fz, 1rem);
      &--regular & {
        font-family: 'Graphik', sans-serif;
        font-weight: 300;
        opacity: 0.6;
      }
    }
  }
  &__subheader {
    &:not(:last-child) {
      margin-bottom: var(--subheader-mb, 0.85rem);
    }
  }
  &__text {
    --text-fz: var(--text-size, 1rem);
    width: 100%;
    &--columns {
      columns: 1;
      column-gap: 2.5rem;
      &--2 {
        @media (max-width: 768px) {
          columns: 2;
        }
      }
      &--3 {
        columns: 3;
      }
    }
    &:not(:last-child) {
      margin-bottom: var(--text-mb, 1.5rem);
    }
  }
  &__buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem; // Adjust gap as needed
    &--mt {
      margin-top:1rem;
    }
  }
  .c_button {
    flex: 1 1 calc(50% - 1rem); // Two buttons per row with gap
  }
}
</style>
